<template>
  <div class="searchBox">
    <input autocomplete="false" placeholder="إبحث عن تعليق" type="search" @keyup.enter="searchNow()" v-model="state.keyword"/>
    <IconsWorker icon="search" color="#999" :width="20" class="searchSideButton" @click="searchNow()" v-if="state.keyword === ''"/>
    <IconsWorker icon="closeF" color="#555" :width="20" class="searchSideButton" @click="clearInput()" v-else/>
  </div>
  <DuList type="commentsControlPanel" :scroll-body="getScrollBody()" :exstra-parameters="{...state.exstraListParams}" :key="state.listKey">
    <template v-slot:noResults>
      <div class="noResults">
        لم يتم العثور على تعليقات
      </div>
    </template>
  </DuList>
</template>

<script>
import DuList from "../../components/DuList";
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive} from "vue";
import IconsWorker from "../../components/IconsWorker";
export default {
  name: "Comments",
  components: {IconsWorker, DuList},
  setup() {

    const state = reactive({
      keyword: '',
      exstraListParams: [],
      listKey: `keyList-${Math.random().toLocaleString(16)}`,
    });

    const refreshListId = () => {
      state.listKey = `keyList-${Math.random().toLocaleString(16)}`;
    }

    const getScrollBody = () => document.getElementsByTagName("body")[0];


    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      buildExtraParams();
    });

    const buildExtraParams = () => {
      state.exstraListParams = {};
      if(route.query.id !== undefined) {
        state.exstraListParams.id = route.query.id;
      }
      if(route.query.postId !== undefined) {
        state.exstraListParams.postId = route.query.postId;
      }
      refreshListId();
    }

    const searchNow = () => {
      state.exstraListParams.keyWord = state.keyword;
      refreshListId();
    }

    const clearInput = () => {
      state.keyword = '';
      refreshListId();
    }

    return {
      state,
      searchNow,
      clearInput,
      getScrollBody,
    }
  }
}
</script>

<style scoped>
  .searchBox{
    width: available;
    border-radius: 100px;
    border: 1px solid #ccc;
    padding:10px 20px;
    margin:10px 20px 50px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
  }
  .searchBox input{
    padding: 5px 0px;
    border-radius: 100px;
    border: 0;
    font-size: 16px;
    flex: 1;
    outline: 0;
  }

  .searchSideButton{
    cursor: pointer;
  }
  .searchSideButton:hover{ opacity: 0.7; }
  .searchSideButton:hover{ opacity: 0.4; }

  .noResults{
    padding: 50px 0;
  }


</style>